<template>
  <div class="home" :style="{backgroundImage: 'url('+require('../../assets/images/start_banner.jpg')+')'}">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="w-100">
            <div class="clearfix">
              <h1>Thanks for visiting!</h1>
              <p>
                Please scan the QR Code or enter the URL in the mailer you
                received, or submit your information through your agent's web
                page to use this tool.
              </p>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.home {
  background-position:top left;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  color: #fff;
}

.home h1 {
  line-height: 400%;
  font-size: 400%;
  color: #fff;
}

</style>
